import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import AttorneysSection from "../components/attorneys-section";
import ContactSection from "../components/contact-section";

export default function AttorneysPage() {
  return (
    <Layout>
      <Seo
        title={`Attorneys`}
        description={`Business lawyers in San Antonio, Texas representing clients in business disputes.`}
      />

      <section className="has-background-white-bis">
        <AttorneysSection large>
          <h1 className="title">Meet Our Lawyers</h1>
          <p className="subtitle mb-6">
            Our attorneys are invested in finding creative ways to help those
            who really need it.
          </p>
        </AttorneysSection>
      </section>
      <section className="has-background-light">
        <ContactSection />
      </section>
    </Layout>
  );
}
